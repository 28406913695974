import Api from "../../../Api";

const END_POINT_UPDATECONFIGINSPECTIONITEMSREALDIMENSIONS = 'user/inspection-sheet/inspection-config/items/real-dimensions/update';

export default{

  UpdateConfigInspectionItemsRealDimensions(data, auth) {
    return Api.patch(END_POINT_UPDATECONFIGINSPECTIONITEMSREALDIMENSIONS, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  }
}