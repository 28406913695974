import Api from "../../../Api";

const END_POINT_UPDATECONFIGINSPECTIONITEMS = 'user/inspection-sheet/inspection-config/items/update';

export default{

  UpdateConfigInspectionItems(data, auth) {
    return Api.patch(END_POINT_UPDATECONFIGINSPECTIONITEMS, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  }
}