import Api from "../../../Api";

const END_POINT_CREATECONFIGINSPECTION = 'user/inspection-sheet/inspection-config/create';
const END_POINT_UPDATECONFIGINSPECTION = 'user/inspection-sheet/inspection-config/update';


export default{

  CreateConfigInspection(data, auth) {
    return Api.post(END_POINT_CREATECONFIGINSPECTION, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateConfigInspection(data, auth) {
    return Api.patch(END_POINT_UPDATECONFIGINSPECTION, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  }
}