<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Hoja de inspección"
      subtitle="| Editar hoja de inspección"
      class="heading-block"
    />    

    <div>
      <base-block rounded title="">
        <div class="block-content font-size-sm">
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-row class="mt-1">
              <b-col sm="8">
                <b-row>
                  <b-col sm="4">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Órden de producción: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          value="1"
                          size="sm"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Nombre del cliente: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          :value="quoteInfo.client.nombre_comercial"
                          size="sm"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Fecha: <span class="text-danger">*</span>
                      </template>
                      <b-form-datepicker
                        id="datepicker"
                        v-model="quoteInfo.inspection_sheet.fecha"
                        placeholder=""
                        class="mb-2"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        >
                      </b-form-datepicker>
                    </b-form-group>
                  </b-col>

                  <b-col sm="4" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Proceso: <span class="text-danger">*</span>
                      </template>
                        <select v-model="quoteInfo.inspection_sheet.proceso" class="form-control">
                            <!--<option value="1">SERVICIO</option>-->
                            <option value="LÁSER">LÁSER</option>
                            <option value="PLASMA">PLASMA</option>
                            <option value="OXICORTE">OXICORTE</option>
                        </select>
                      </b-form-group>
                  </b-col>
                  <b-col sm="4" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                            EQMED: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.inspection_sheet.eqmed"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                            Responsable: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.inspection_sheet.responsable"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="4" class="rounded border p-4 pt-0">
                <FileSystem v-if="quote_id != ''" view="cotizacion" :idItemView="quote_id" :files="files"></FileSystem>
              </b-col>
            </b-row>
            <b-row class="mt-3">
            </b-row>
            <h4 class="mt-3">Inspección visual</h4>
            <b-row class="mt-3">
              <b-col sm="2">
                <b-form-group >
                  <template #label>
                      Óxido:
                  </template>
                  <b-form-radio-group v-model="quoteInfo.inspection_sheet.oxido">
                    <b-form-radio :value="1">Si</b-form-radio>
                    <b-form-radio :value="0">No</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>

              <b-col sm="2">
                <b-form-group >
                  <template #label>
                      Rayones:
                  </template>
                  <b-form-radio-group v-model="quoteInfo.inspection_sheet.rayones">
                    <b-form-radio :value="1">Si</b-form-radio>
                    <b-form-radio :value="0">No</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>

              <b-col sm="2">
                <b-form-group >
                  <template #label>
                      Poros:
                  </template>
                  <b-form-radio-group v-model="quoteInfo.inspection_sheet.poros">
                    <b-form-radio :value="1">Si</b-form-radio>
                    <b-form-radio :value="0">No</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>

              <b-col sm="2">
                <b-form-group >
                  <template #label>
                      Golpes:
                  </template>
                  <b-form-radio-group v-model="quoteInfo.inspection_sheet.golpes">
                    <b-form-radio :value="1">Si</b-form-radio>
                    <b-form-radio :value="0">No</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>

              <b-col sm="2">
                <b-form-group >
                  <template #label>
                      Deformación:
                  </template>
                  <b-form-radio-group v-model="quoteInfo.inspection_sheet.deformacion">
                    <b-form-radio :value="1">Si</b-form-radio>
                    <b-form-radio :value="0">No</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>                
              </b-col>
            </b-row>
            <br />
            <template v-if="true" >
              <div class="rounded border mt-3 p-3 label-small" v-for="(item_producto_terminado, index_Q_F_P) in selected_list_items_producto_terminado" :key="item_producto_terminado.q_f_p_id">
                <b-row>
                  <b-col sm="1">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Partida:
                      </template>
                        <b-form-input
                          :value="`${index_Q_F_P+1}`"
                          size="sm"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="1">
                    <b-form-group label-for="no_cliente" class="pl-3">
                      <template #label>
                        Piezas:
                      </template>
                        <b-form-input
                          size="sm"
                          disabled
                          v-model="item_producto_terminado.cantidad"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Descripción: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          size="sm"
                          disabled
                          v-model="item_producto_terminado.no_parte"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="1" v-if="typeof item_producto_terminado.finished_product.calibre !== 'undefined' && item_producto_terminado.finished_product.calibre !== '' && parseFloat(item_producto_terminado.finished_product.calibre) > 0">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Espesor: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="item_producto_terminado.finished_product.calibre"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="1" v-if="typeof item_producto_terminado.finished_product.calibre === 'undefined' || item_producto_terminado.finished_product.calibre === '' || item_producto_terminado.finished_product.calibre == null || parseFloat(item_producto_terminado.finished_product.calibre) <= 0">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Espesor: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="item_producto_terminado.isqfp_inspection.espesor"
                          size="sm"                          
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="1">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Real: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="item_producto_terminado.isqfp_inspection.real"
                          size="sm"                          
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="2">
                    <b-form-group label-for="no_cliente" class="pl-3">
                      <template #label>
                        # de inspecciones: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          value="1"
                          size="sm"
                          v-model="item_producto_terminado.isqfp_inspection.no_inspecciones"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="2">
                    <b-form-group label-for="no_cliente" class="pl-3">
                      <template #label>
                        Cantidad a inspeccionar: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          value="1"
                          size="sm"
                          v-model="item_producto_terminado.isqfp_inspection.cantidad_a_inspeccionar"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="2">
                    <b-form-group label-for="no_cliente" class="pl-3" append="%">
                      <template #label>
                        Muestreo:
                      </template>
                        <b-input-group size="sm" append="%">
                          <b-form-input
                            size="xs"
                            disabled
                            :value="item_producto_terminado.isqfp_inspection.muestreo = ((item_producto_terminado.isqfp_inspection.cantidad_a_inspeccionar *100) / item_producto_terminado.cantidad).toFixed(2)"
                            v-model="item_producto_terminado.isqfp_inspection.muestreo"
                          ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!--<b-row class="mt-3">

                  <b-col sm="2">
                    <b-form-group label-for="no_cliente" class="pl-3">
                      <template #label>
                        # de parte
                      </template>
                        <b-form-input
                          size="sm"
                          v-model="item_producto_terminado.no_parte"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>

                </b-row>-->

                <b-row>
                  <b-col offset-sm="5" sm="3" class="text-right mt-3">
                    <b-form-group label-for="no_cliente" class="pl-3 text-right mt-2">
                      <b-form-checkbox
                        v-model="item_producto_terminado.isqfp_inspection.actualizar_cantidades"
                      >
                        <p class="text-right" style="margin-bottom: 0px; font-size: 14px;">Actualizar cantidades de inspección</p>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" class="text-right mt-3">
                    <b-form-group label-for="no_cliente">
                      <b-button variant="primary" @click="item_producto_terminado.isqfp_inspection.id > 0 ? ActualizarConfigInspection(index_Q_F_P) : CrearConfigInspection(index_Q_F_P)">Paso 1: Guardar configuración de inspección</b-button>
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row class="mt-2 p-3">
                  <b-col sm="12">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col" v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones"><p class="text-center" style="margin-bottom: 0px!important">{{index_no_inspecciones+1}}</p></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Tolerancia</th>
                            <td v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones">
                              <b-form-group>
                                  <b-form-input
                                    v-model="inspection_item.tolerancia"
                                    size="sm"
                                  ></b-form-input>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Espec</th>
                            <td v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones">
                              <b-form-group>
                                  <b-form-input
                                    v-model="inspection_item.espec"
                                    size="sm"
                                  ></b-form-input>
                              </b-form-group>
                            </td>                          
                          </tr>
                          <tr>
                            <th scope="row">MAX</th>
                            <td v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones">
                              <b-form-group>
                                  <b-form-input
                                    :value="parseFloat(inspection_item.espec) + parseFloat(inspection_item.tolerancia)"
                                    disabled
                                    size="sm"
                                  ></b-form-input>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">MIN</th>
                            <td v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones">
                              <b-form-group>
                                  <b-form-input
                                    :value="parseFloat(inspection_item.espec) - parseFloat(inspection_item.tolerancia)"
                                    disabled
                                    size="sm"
                                  ></b-form-input>
                              </b-form-group>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                  <b-col offset-sm="7" sm="5" class="text-right">
                    <b-form-group label-for="no_cliente" class="pl-3">
                      <b-button variant="primary" @click="ActualizarConfigInspectionItems(index_Q_F_P)">Paso 2: Guardar valores de inspección</b-button>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-2 p3">
                  <p style="border: none!important; width: 100%; margin-bottom: 10px!important; text-align: center;">DIMENSIÓN REAL PRIMERA PIEZA</p>
                  <b-col sm="12">
                    <table class="table">
                      <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col" v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones"><p class="text-center" style="margin-bottom: 0px!important">{{index_no_inspecciones+1}}</p></th>
                          </tr>
                        </thead>
                      <tbody>
                        <tr>
                          <th scope="row">{{ 1 }}</th>
                          <td v-for="(inspection_item, index_item) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_item">
                            <b-form-group v-for="(inspection_rd_item, index_rd_item) in inspection_item.real_dimensions" :key="index_rd_item">
                              <b-form-input
                                v-if="index_rd_item == 0 && inspection_rd_item.tipo=='primera_pieza'"
                                v-model="inspection_rd_item.valor"
                                size="sm"
                              ></b-form-input>
                            </b-form-group>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col offset-sm="7" sm="5" class="text-right">
                    <b-form-group label-for="no_cliente" class="pl-3">
                      <b-button variant="primary" @click="ActualizarDimensionesReales(index_Q_F_P)">Guardar valores de inspección por pieza</b-button>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-2 p3">
                  <p style="border: none!important; width: 100%; margin-bottom: 10px!important; text-align: center;">DIMENSIÓN REAL OPERADOR</p>
                  <b-col sm="12">
                    <table class="table">
                      <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col" v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones"><p class="text-center" style="margin-bottom: 0px!important">{{index_no_inspecciones+1}}</p></th>
                          </tr>
                        </thead>
                      <tbody>
                        <tr v-for="(real_dimension, index) in item_producto_terminado.isqfp_inspection.cantidad_a_inspeccionar" :key="index">
                          <th scope="row">{{ index+1 }}</th>
                          <td v-for="(inspection_item, index_item) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_item">
                            <div v-for="(real_dimension, indexIn) in item_producto_terminado.isqfp_inspection.cantidad_a_inspeccionar" :key="indexIn">
                              <div v-if="indexIn == index">
                                <p v-if="false">index a imprimir: {{ indexIn }}</p>
                                <p v-if="false">{{ rdo=0 }}</p>
                                <b-form-group v-for="(inspection_rd_item,index_rd_item) in inspection_item.real_dimensions" :key="index_rd_item">
                                  <p v-if="false">index de real dimension {{ index_rd_item }}</p>
                                  <p v-if="false">tipo de real dimension {{ inspection_rd_item.tipo }}</p>
                                  <p v-if="false">{{ (inspection_rd_item.tipo=='operador') ? (rdo++):'no es' }}</p>
                                  <p v-if="false">Numero de real dimensions con tipo 'operador'= {{ rdo }}</p>
                                  <b-form-input
                                    v-if="indexIn+1 == index_rd_item && inspection_rd_item.tipo=='operador'"
                                    v-model="inspection_rd_item.valor"
                                    size="sm"
                                  ></b-form-input>
                                </b-form-group>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col offset-sm="7" sm="5" class="text-right">
                    <b-form-group label-for="no_cliente" class="pl-3">
                      <b-button variant="primary" @click="ActualizarDimensionesReales(index_Q_F_P)">Guardar valores de inspección por operador</b-button>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-2 p3">
                  <p style="border: none!important; width: 100%; margin-bottom: 10px!important; text-align: center;">DIMENSIÓN REAL PRODUCCIÓN</p>
                  <b-col sm="12">
                    <table class="table">
                      <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col" v-for="(inspection_item, index_no_inspecciones) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_no_inspecciones"><p class="text-center" style="margin-bottom: 0px!important">{{index_no_inspecciones+1}}</p></th>
                          </tr>
                        </thead>
                      <tbody>
                        <tr v-for="(real_dimension, index) in item_producto_terminado.isqfp_inspection.cantidad_a_inspeccionar" :key="index">
                          <th scope="row">{{ index+1 }}</th>
                          <td v-for="(inspection_item, index_item) in item_producto_terminado.isqfp_inspection.inspection_items" :key="index_item">
                            <div v-for="(real_dimension, indexIn) in item_producto_terminado.isqfp_inspection.cantidad_a_inspeccionar" :key="indexIn" >
                              <div v-if="indexIn == index">
                                <p v-if="false">index a imprimir: {{ indexIn }}</p>
                                <p v-if="false">{{ rdp=0 }}</p>
                                <p v-if="false">{{ rdo++ }}</p>
                                <b-form-group v-for="(inspection_rd_item,index_rd_item) in inspection_item.real_dimensions" :key="index_rd_item">
                                  <p v-if="false">index de real dimension: {{ index_rd_item }}</p>
                                  <p v-if="false">&nbsp;</p>
                                  <p v-if="false">tipo de real dimension: {{ inspection_rd_item.tipo }}</p>
                                  <p v-if="false">{{ (inspection_rd_item.tipo=='produccion') ? (rdp++):'no es' }}</p>
                                  <p v-if="false">Numero de real dimensions con tipo 'operador'= {{ rdp }}</p>
                                  <b-form-input
                                    v-if="indexIn+(item_producto_terminado.isqfp_inspection.cantidad_a_inspeccionar+1) == index_rd_item && inspection_rd_item.tipo=='produccion'"
                                    v-model="inspection_rd_item.valor"
                                    size="sm"
                                  ></b-form-input>
                                </b-form-group>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col offset-sm="7" sm="5" class="text-right">
                    <b-form-group label-for="no_cliente" class="pl-3">
                      <b-button variant="primary" @click="ActualizarDimensionesReales(index_Q_F_P)">Guardar valores de inspección producción</b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </template>
            <br />
          </form>
        </div>
        <div  class="block-content block-content-full text-right border-top">
          <b-button variant="alt-primary"  class="mr-1"  @click="Cancelar()">Cancelar</b-button>
          <b-button variant="primary" @click="UpdateInspectionSheet">Guardar hoja</b-button>
        </div>
        </base-block>
    </div>
  </div>
</template>

<script>

  
  import PreCotizacion from "../../../api/admin/cotizacion/Pre_cotizacion";

  import HojaInspeccion from "../../../api/admin/hoja_inspeccion/Hoja_Inspeccion";
  import ConfigInspection from "../../../api/admin/hoja_inspeccion/config_inspection/Config_Inspection";
  import ConfigInspectionItems from "../../../api/admin/hoja_inspeccion/items_inspection/Items_Inspection";
  import RealDimensions from "../../../api/admin/hoja_inspeccion/real_dimensions/Real_Dimensions";

  import FileSystem from "../../../components/FileSystem.vue"

  import Swal from "sweetalert2";

  export default {

    components: {
      FileSystem
    },

    data() {
      return {
        auxTable:0,
        currentQuote:0,
        quoteInfo:null,
        quote_id: null,
        inspectionSheetId:null,
        form: {
          fecha:null,
          proceso:null,
          eqmed:null,
          responsable:'',
          oxido:false,
          rayones:false,
          poros:false,
          golpes:false,
          deformacion:false
        },
        inspeccion_items_tabla_2:[{fila:1}],
        selected_list_items_producto_terminado:[],
      }
    },

    methods: {

      Cancelar(){
        this.$router.push({
          path: "/cotizacion/list"
        });
      },

      GetQuoteInfo(id){
        const context = this;
        let auth = JSON.parse(localStorage.autentication);
        PreCotizacion.GetQuot(id,auth)
        .then((response) => {
          const responseQuoteInfo = response.data.data
          context.quoteInfo = responseQuoteInfo
          context.selected_list_items_producto_terminado = responseQuoteInfo.quote_finished_products
          context.selected_list_items_producto_terminado.forEach((element) => {
            if(element.isqfp_inspection == null){
              element.isqfp_inspection = {
                id : 0,
                descripcion : '',
                espesor : 0.0,
                real : 0.0,
                no_inspecciones : 0,
                cantidad_a_inspeccionar : 0,
                muestreo : 0.0,
                q_f_p_id : element.id
              }
            }
          })
          context.files = responseQuoteInfo.files
          console.log(context.quoteInfo)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      UpdateInspectionSheet(){
        const context = this;
        let auth = JSON.parse(localStorage.autentication);

        HojaInspeccion.UpdateHojaInspeccion(context.quoteInfo.inspection_sheet,auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      CrearConfigInspection(index){
        const context = this;
        let auth = JSON.parse(localStorage.autentication);
        const data = context.selected_list_items_producto_terminado[index].isqfp_inspection
        ConfigInspection.CreateConfigInspection(data,auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          // Cuando nosotros guardamos la fonfiguración, necesitamos crear el número de registros
          // en la tabla nueva de ISQFPIItems
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      ActualizarConfigInspection(index){
        const context = this;
        console.log(context.selected_list_items_producto_terminado[index].isqfp_inspection.actualizar_cantidades)
        if(context.selected_list_items_producto_terminado[index].isqfp_inspection.actualizar_cantidades){
          Swal.fire({
              title: "¡Cuidado!",
              text: "Esta cofiguración, ya tenía valores previos, si continua, la información de las tablas de inspección se borrará, para actulizar las nuevas cantidades de inspección (# de inspecciones y cantidad a inspeccionar).",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: "Sí, actualizar",
              cancelButtonText: "Cancelar",
          })
          .then(resultado => {
            if (resultado.value) {
              let auth = JSON.parse(localStorage.autentication);
              const data = context.selected_list_items_producto_terminado[index].isqfp_inspection
              ConfigInspection.UpdateConfigInspection(data,auth)
              .then((response) => {
                Swal.fire({
                  title: "Genial!",
                  text: response.data.message,
                  icon: "success",
                });
                context.GetQuoteInfo(context.quote_id)
              })
              .catch((error) => {
                const obj = error.response.data.errors;
                Object.entries(obj).forEach( elements => 
                  Swal.fire({
                    title: "Que mal!",
                    text: elements[1][0],
                    icon: "error",
                  })
                );
              });
            }
          });  
        } else {
          let auth = JSON.parse(localStorage.autentication);
          const data = context.selected_list_items_producto_terminado[index].isqfp_inspection
          ConfigInspection.UpdateConfigInspection(data,auth)
          .then((response) => {
            Swal.fire({
              title: "Genial!",
              text: response.data.message,
              icon: "success",
            });
            context.GetQuoteInfo(context.quote_id)
          })
          .catch((error) => {
            const obj = error.response.data.errors;
            Object.entries(obj).forEach( elements => 
              Swal.fire({
                title: "Que mal!",
                text: elements[1][0],
                icon: "error",
              })
            );
          });
        }
      },

      ActualizarConfigInspectionItems(index){
        const context = this;
        let auth = JSON.parse(localStorage.autentication);
        const data = context.selected_list_items_producto_terminado[index].isqfp_inspection.inspection_items


        data.forEach((element) => {
          element.max = element.espec + element.tolerancia
          element.min = element.espec - element.tolerancia
        })
        
        ConfigInspectionItems.UpdateConfigInspectionItems(data,auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      ActualizarDimensionesReales(index){
        const context = this;
        let auth = JSON.parse(localStorage.autentication);
        const data = context.selected_list_items_producto_terminado[index].isqfp_inspection.inspection_items

        RealDimensions.UpdateConfigInspectionItemsRealDimensions(data,auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      /**
      * Esta seccion es de la lógica de esta hoja
      */
      agregarFila(){
        this.inspeccion_items_tabla_2.push([{item:2}])
        console.log("agregando fila")
      }

    },    

    mounted() {
      let me = this
      me.quote_id = me.$route.params.id
      me.GetQuoteInfo(me.quote_id)
    }
  };



</script>


<style scoped>
    hr#linea {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top: 1px solid #030303;
    }

    hr{
      border-top: 1px solid #000000 !important;
    }

    .form-group{
      margin-bottom: 5px!important;
    }

    .label-small {
      font-size: 13px!important;
    }

</style>